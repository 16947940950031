import service from './service'

const BASE_CONTEXT = '/ywswmsservice'

//查询省市区接口
export function areal3Api(params) {
  return service.request({
    url: BASE_CONTEXT + '/common/area/l3',
    method: 'GET',
    params,
  })
}

//文件上传接口
export function fileUpload(data) {
  return service.request({
    url: BASE_CONTEXT + '/common/uploadfiles',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}

export function downloadFile(params) {
  return service.request({
    url: BASE_CONTEXT + '/common/downloadfile',
    method: 'POST',
    params,
  })
}

export function userregisterfeedback(data) {
  return service.request({
    url: BASE_CONTEXT + '/sw/register/feedback',
    method: 'POST',
    data,
  })
}

export function orgregisterfeedback(data) {
  return service.request({
    url: BASE_CONTEXT + '/web/org/feedback',
    method: 'POST',
    data,
  })
}

export function userregisterApi(data) {
  return service.request({
    url: BASE_CONTEXT + '/sw/register',
    method: 'POST',
    data,
  })
}

export function orgregisterApi(data) {
  return service.request({
    url: BASE_CONTEXT + '/web/org/register',
    method: 'POST',
    data,
  })
}

export function syslogin(data) {
  return service.request({
    url: BASE_CONTEXT + '/security/gw/login',
    method: 'POST',
    data,
  })
}

export function accoountInfoApi(data) {
  return service.request({
    url: BASE_CONTEXT + '/security/gw/account/info',
    method: 'POST',
    data,
  })
}

export function loginoutApi(data) {
  return service.request({
    url: BASE_CONTEXT + '/security/loginout',
    method: 'POST',
    data,
  })
}

export function getcaptcha(data) {
  return service.request({
    url: BASE_CONTEXT + '/common/getcaptcha',
    method: 'POST',
    data,
  })
}

export function getSwZsglh(params) {
  return service.request({
    url: BASE_CONTEXT + '/sw/zsglh',
    method: 'GET',
    params,
  })
}

export function studyfreeloginapi(params) {
  return service.request({
    url: BASE_CONTEXT + '/study/free/login',
    method: 'GET',
    params,
  })
}

export function getstudyurl(params) {
  return service.request({
    url: BASE_CONTEXT + '/common/studyurl',
    method: 'GET',
    params,
  })
}

export function resetPwdSendEmail(data) {
  return service.request({
    url: BASE_CONTEXT + '/sw/password/reset/sendemail',
    method: 'POST',
    data,
  })
}

export function swResetPwdDo(data) {
  return service.request({
    url: BASE_CONTEXT + '/sw/password/reset/do',
    method: 'POST',
    data,
  })
}

export function orgResetPwdSendEmail(data) {
  return service.request({
    url: BASE_CONTEXT + '/web/org/password/reset/sendemail',
    method: 'POST',
    data,
  })
}

export function orgResetPwdDo(data) {
  return service.request({
    url: BASE_CONTEXT + '/web/org/password/reset/do',
    method: 'POST',
    data,
  })
}

export function swSubmitAppeal(data) {
  return service.request({
    url: BASE_CONTEXT + '/sw/appeal/submit',
    method: 'POST',
    data,
  })
}
export function swGetAreaidForAppeal(data) {
  return service.request({
    url: BASE_CONTEXT + '/web/sw/getareaid',
    method: 'POST',
    data,
  })
}
//查询项目公告
export function getProject(data) {
  return service.request({
    url: BASE_CONTEXT + '/web/project/announce',
    method: 'POST',
    data,
  })
}

export function getProjectDetailsForWeb(data) {
  return service.request({
    url: BASE_CONTEXT + '/web/project/details',
    method: 'POST',
    data,
  })
}

export function getProjectListForWeb(data) {
  return service.request({
    url: BASE_CONTEXT + '/web/project/list',
    method: 'POST',
    data,
  })
}

export function getOrgRegisterListForWeb(data) {
  return service.request({
    url: BASE_CONTEXT + '/web/org/list',
    method: 'POST',
    data,
  })
}
export function getOrgRegisterDetailsForWeb(data) {
  return service.request({
    url: BASE_CONTEXT + '/web/org/details',
    method: 'POST',
    data,
  })
}

export function getOrgRecommendListForWeb(data) {
  return service.request({
    url: BASE_CONTEXT + '/web/org/recommend',
    method: 'POST',
    data,
  })
}

//查询社工机构下的社工列表
export function getWorkersListData(data) {
  return service.request({
    url: BASE_CONTEXT + '/web/sw/list',
    method: 'POST',
    data,
  })
}
//查询社工机构下的项目列表
export function getItemsListData(data) {
  return service.request({
    url: BASE_CONTEXT + '/web/project/listByUnicode',
    method: 'POST',
    data,
  })
}

/**
 * 根据代码名称查询代码值
 *  */
export function getCodesByName(data) {
  return service.request({
    url: BASE_CONTEXT + '/common/getcodes?codeTypeName=' + data,
    method: 'GET',
  })
}

/**
 * 获取首页跳转链接集合
 * @param {*} data 
 * @returns 
 */
export function getIndexWebMenuList(data) {
  return service.request({
    url: BASE_CONTEXT + '/integratedmanage/webMenu/indexWebMenuList',
    method: 'POST',
    data,
  })
}

/**
 * 获取首页菜单新闻类型集合
 * @param {*} data 
 * @returns 
 */
export function getIndexWebNewsList(data) {
  return service.request({
    url: BASE_CONTEXT + '/integratedmanage/webNews/indexWebNewsList',
    method: 'POST',
    data,
  })
}

/**
 * 获取首页菜单新闻信息
 * @param {*} data 
 * @returns 
 */
export function getIndexWebNewsInfo(data) {
  return service.request({
    url: BASE_CONTEXT + '/integratedmanage/webNews/indexWebNewsInfo',
    method: 'POST',
    data,
  })
}

/**
 * 获取首页标签新闻信息
 * @param {*} data 
 * @returns 
 */
export function getIndexWebNewsTab(data) {
  return service.request({
    url: BASE_CONTEXT + '/integratedmanage/webNews/indexWebNewsTab',
    method: 'POST',
    data,
  })
}

/**
 * 手机短信验证码登录,发送短信
 * @param {*} data 
 * @returns 
 */
export function gwPhoneCodeLoginSend(data) {
  return service.request({
    url: BASE_CONTEXT + '/security/gw/phone/code/login/send',
    method: 'POST',
    data,
  })
}

/**
 * 手机短信验证码登录
 * @param {*} data 
 * @returns 
 */
export function gwPhoneCodeLogin(data) {
  return service.request({
    url: BASE_CONTEXT + '/security/gw/phone/code/login',
    method: 'POST',
    data,
  })
}

export function getHeadlines(params) {
  return service.request({
    url: BASE_CONTEXT + '/local/gw/headlines',
    method: 'GET',
    params,
  })
}

export function getNews(params) {
  return service.request({
    url: BASE_CONTEXT + '/local/gw/news',
    method: 'GET',
    params,
  })
}

export function getNewsGroupbydate(data) {
  return service.request({
    url: BASE_CONTEXT + '/local/gw/news/groupbydate',
    method: 'POST',
    data,
  })
}

export function getNewsInfo(params) {
  return service.request({
    url: BASE_CONTEXT + '/local/gw/newsinfo',
    method: 'GET',
    params,
  })
}

///查询访问量接口
export function getVisitNums(params) {
  return service.request({
    url: BASE_CONTEXT + '/local/gw/visitnum',
    method: 'GET',
    params,
  })
}
//增加访问量接口
export function AddVisitNums(params) {
  return service.request({
    url: BASE_CONTEXT + '/local/gw/addvisitnum',
    method: 'GET',
    params,
  })
}
//查询官网首页新闻
export function getGWNewLists(data) {
  return service.request({
    url: BASE_CONTEXT + '/local/gw/gwnews',
    method: 'POST',
    data,
  })
}

//治理创新
export function getInnovationList(data) {
  return service.request({
    url: BASE_CONTEXT + '/local/gw/zlcx/list',
    method: 'POST',
    data,
  })
}
//治理创新详情
export function getDetailsInfo(params) {
  return service.request({
    url: BASE_CONTEXT + '/local/gw/zlcx/list/info',
    method: 'GET',
    params,
  })
}

//嘉宾访谈
export function getTalentsList(data) {
  return service.request({
    url: BASE_CONTEXT + '/local/gw/talents/list',
    method: 'POST',
    data,
  })
}

//嘉宾访谈详情
export function getTalentsInfo(params) {
  return service.request({
    url: BASE_CONTEXT + '/local/gw/talents/list/info',
    method: 'GET',
    params,
  })
}

//其它嘉宾访谈详情
export function getOtherTalentsInfo(params) {
  return service.request({
    url: BASE_CONTEXT + '/local/gw/othertalents/list/info',
    method: 'GET',
    params,
  })
}

export function trainProjectList(data) {
  return service.request({
    url: BASE_CONTEXT + "/train/project/web/list",
    method: 'POST',
    data,
  })
}

//新增留言
export function addLiuYan(data) {
  return service.request({
    url: BASE_CONTEXT + "/integratedmanage/web/leave/message/add",
    method: 'POST',
    data,
  })
}

export function cooperationInsert(data) {
  return service.request({
    url: BASE_CONTEXT + "/integratedmanage/web/cooperation/intention/add",
    method: 'POST',
    data,
  })
}

//教材订购
export function addBookOrderForWeb(data) {
  return service.request({
    url: BASE_CONTEXT + '/integratedmanage/web/book/order/add',
    method: 'POST',
    data,
  })
}

//合作机构
export function searchCooperationOrgList(data) {
  return service.request({
    url: BASE_CONTEXT + '/continuing/edu/cooperation/org/list/search',
    method: 'POST',
    data,
  })
}
//精品课程
export function searchCourseconfigList(data) {
  return service.request({
    url: BASE_CONTEXT + '/continuing/edu/courseconfig/list/search',
    method: 'POST',
    data,
  })
}
//班型介绍
export function searchClassTypeLists(data) {
  return service.request({
    url: BASE_CONTEXT + '/continuing/edu/classtype/list/search',
    method: 'POST',
    data,
  })
}
//课程优势
export function searchAdvantageListSearch(data) {
  return service.request({
    url: BASE_CONTEXT + '/continuing/edu/courseadvantage/list/search',
    method: 'POST',
    data,
  })
}

//直播预约
export function searchLivesList(data) {
  return service.request({
    url: BASE_CONTEXT + '/continuing/edu/livebroadcast/list/search',
    method: 'POST',
    data,
  })
}
//师资团队
export function searchTeacherList(data) {
  return service.request({
    url: BASE_CONTEXT + '/continuing/edu/teacher/list/search',
    method: 'POST',
    data,
  })
}

//备考专区
export function searchPrepareExamZoneList(data) {
  return service.request({
    url: BASE_CONTEXT + '/continuing/edu/prepare/exam/zone/list/search',
    method: 'POST',
    data,
  })
}

//备考专区---链接
export function searchPrepareExamZoneData(data) {
  return service.request({
    url: BASE_CONTEXT + '/continuing/edu/prepare/exam/zone/list/searchOne',
    method: 'POST',
    data,
  })
}

//备考专区---推荐书籍详情
export function searchPrepareExamZoneBookData(data) {
  return service.request({
    url: BASE_CONTEXT + '/continuing/edu/prepare/exam/zone/list/searchBook',
    method: 'POST',
    data,
  })
}

//陪考服务
export function searchAssistExamServiceList(data) {
  return service.request({
    url: BASE_CONTEXT + '/continuing/edu/assist/exam/service/list/search',
    method: 'POST',
    data,
  })
}

//培训项目报名接口
export function trainProjectSign(data){
  return service.request({
    url: BASE_CONTEXT + '/train/project/web/sign',
    method: 'POST',
    data,
  })
}

//更新查看次数
export function updatereadnum(data) {
  return service.request({
    url: BASE_CONTEXT + '/web/project/updatereadnum/' + data.projectId,
    method: 'POST',
    data,
  })
}

export function deliverBiographicalNotes(data) {
  return service.request({
    url: BASE_CONTEXT + '/recruit/notes/deliver',
    method: 'POST',
    data,
  })
}

export function getBiographicalNotes(data) {
  return service.request({
    url: BASE_CONTEXT + '/recruit/notes/info',
    method: 'POST',
    data,
  })
}

export function getRecruitInfoList(data) {
  return service.request({
    url: BASE_CONTEXT + '/recruit/list/web',
    method: 'POST',
    data,
  })
}

export function getRecruitInfo(data) {
  return service.request({
    url: BASE_CONTEXT + '/recruit/info',
    method: 'POST',
    data,
  })
}

export function addTryForWebApi(data){
  return service.request({
    url: BASE_CONTEXT+'/integratedmanage/web/try/add',
    method: 'POST',
    data:data
  })
}

export function addRecruitPageViewApi(data) {
  return service.request({
    url: BASE_CONTEXT + '/recruit/web/add/page/view',
    method: 'POST',
    data,
  })
}

export function getVisitNumsByOrigin(params) {
  return service.request({
    url: BASE_CONTEXT + '/local/gw/visitnum/origin',
    method: 'GET',
    params,
  })
}
//增加访问量接口
export function AddVistNums(params) {
  return service.request({
    url: BASE_CONTEXT + '/local/gw/addvisitnum',
    method: 'GET',
    params,
  })
}

//查询访问量接口
export function getVistNums(params) {
  return service.request({
    url: BASE_CONTEXT + '/local/gw/visitnum',
    method: 'GET',
    params,
  })
}