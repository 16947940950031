<template>
  <div>
    <div
      class="l-mask"
      :style="{ zIndex: isVisabled ? zIndex : -1 }"
      :class="{ show: isVisabled }"
      @click="maskClick"
    ></div>
    <div
      class="l-pop"
      :style="{ zIndex: isVisabled ? zIndex + 1 : -1 }"
      :class="{ show: isVisabled }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'l-pop',
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      typeof: Boolean,
      default: false,
    },
    zIndex: {
      typeof: Number,
      default: 1001,
    },
    maskClickVisabled: {
      typeof: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isVisabled: this.visible,
    }
  },
  mounted() {
    if (this.visible) {
      document.querySelector('html').style.overflow = 'hidden'
    }
  },
  watch: {
    visible(v) {
      if (v) {
        document.querySelector('html').style.overflow = 'hidden'
      } else {
        document.querySelector('html').style.overflow = 'auto'
      }
      this.isVisabled = v
    },
  },
  methods: {
    onChange() {
      this.isVisabled = false
      this.$emit('change', this.isVisabled)
    },
    maskClick() {
      if (this.maskClickVisabled) {
        this.isVisabled = false
        this.$emit('change', this.isVisabled)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.l {
  &-mask {
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.45);
    transition: 0.2s;
    opacity: 0;
    z-index: -1;
    &.show {
      opacity: 1;
      // z-index: 1001;
    }
  }
  &-pop {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.2s;
    opacity: 0;
    z-index: -1;
    &.show {
      opacity: 1;
      // z-index: 1002;
    }
  }
}
</style>