<template>
  <div class="l-footer">
    <div class="l-mid">
      <div class="l-footer__main">
        <div class="l-footer__left">
          <img src="@/assets/images/logo_light.png" alt="" class="l-logo" />
          <div class="l-friendlylink">
            <!-- <p>友情链接：</p> -->
            <a v-for="(item, index) in links" :key="index" :href="item.path" target="_blank">{{ item.label }}</a>
          </div>
          <div class="visitnum">
          <p>浏览网站人数统计：</p>
          <span v-for="item, index in visitnum" :key="index">{{ item }}</span>
        </div>
          <p class="l-contact"><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023092996号</a></p>
          
        </div>
        <div class="l-footer__right">
          
          <div class="l-qrcode">
            <img src="https://download.yxybb.com/project/IEA/ywjxjy/ywkfqrcode20240320.png" alt="" />
            <p>扫一扫添加微信客服</p>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVisitNumsByOrigin } from "@/api/sgapi";
export default {
  name: "l-footer",
  props: {},
  data() {
    return {
      visitnum:[],
      links: [

      ],
    }
  },
  created() {
    getVisitNumsByOrigin({ origin: process.env.VUE_APP_OUTPUTDIR })
      .then((res) => {
        if (res) {
          let str = '12689'
          let vn = res.data
          if (vn <= 12689) {
            vn = vn + 12689
          }
          str = String(vn)
          for (const key in str) {
            this.visitnum.push(str[key])
          }
        }
      })
      .catch((e) => { })
  }
}
</script>

<style lang="less" scoped>
.l {
  &-footer {
    background: #1a8bf9;
    height: 422rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__main {
      flex: 1;
      overflow: hidden;
      display: flex;
    }

    &__left {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .visitnum{
        display: flex;
        align-items: center;
        gap: 0 12rem;

        p {
        font-size: 16rem;
        line-height: 42rem;
        color: #fff;
      }
        span {
          display: inline-flex;
          align-items: center;
          height: 28rem;
          line-height: 26rem;
          padding: 0 6rem;
          font-size: 16rem;
          color: #fff;
          font-weight: 500;
          border: 1rem solid hsla(0,0%,100%,.4);
          box-sizing: border-box;
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;


    }

    &__suffix {
      border-top: 2rem solid rgba(255, 255, 255, 0.2);
      height: 76rem;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        font-size: 12rem;
        color: #fff;
      }
    }
  }

  &-logo {
    height: 94rem;
    vertical-align: top;
  }

  &-friendlylink {
    margin-top: 36rem;
    display: flex;
    align-items: center;
    font-size: 20rem;
    color: #fff;
    font-weight: 500;

    p {
      margin-right: 32rem;
      font-size: inherit;
      color: inherit;
    }

    a {
      margin-right: 60rem;
      font-size: inherit;
      color: inherit;
    }
  }

  &-contact {
    font-size: 16rem;
    color: #fff;
    margin-top: 14rem;

    &:nth-of-type(1) {
      margin-top: 24rem;
    }

    a {
      color: #fff;
    }
  }

  &-qrcode {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 144rem;
      vertical-align: top;
    }

    p {
      margin-top: 16rem;
      font-size: 16rem;
      color: #fff;
    }
  }
}
</style>
