<template>
  <div>
    <div class="l-fixed">
      <div class="l-fixed__tooltip" v-if="$route.path === '/train'">
        <h3>热门资讯</h3>
        <ul>
          <li @click="onService()">报考通知</li>
          <li @click="onService()">报考条件</li>
          <li @click="onService()">报名指南</li>
          <li @click="onService()">考场注意事项</li>
          <!-- <li>成绩查询</li>
          <li>证书领取</li> -->
        </ul>
        <button @click="onService()">常见问题</button>
      </div>
      <div @click="msgVisible = true" class="l-fixed__button" v-if="$route.path === '/ptjs'">
        <img src="@/assets/images/liuyan.png" alt="" />
      </div>
      <div class="l-fixed__button" @click="onService">
        <img src="@/assets/imgs/icon12.png" alt="" />
      </div>
      <div class="l-fixed__button" @click="onBackTop">
        <img src="@/assets/imgs/icon13.png" alt="" />
      </div>
    </div>
    <l-pop v-model="msgVisible">
      <div class="l-popup">
        <l-title title="留言">
          <img slot="suffix" class="l-close" src="@/assets/images/close.png" alt="" @click="msgVisible = false" />
        </l-title>
        <div class="l-form">
          <input type="text" placeholder="请输入姓名" class="l-input" v-model="liuyanForm.contactPeople" />
          <input type="text" placeholder="请输入手机号" class="l-input" style="margin-top: 20rem"
            v-model="liuyanForm.contactPhone" />
          <textarea v-model="liuyanForm.content" style="margin-top: 20rem" class="l-textarea" name="" id="" cols="30"
            rows="10" placeholder="请输入留言内容"></textarea>
          <button class="l-button" style="margin-top: 40rem" @click="onSubmitMsg()">
            提交
          </button>
        </div>
      </div>
    </l-pop>
  </div>
</template>

<script>
import LPop from '@/components/LPop.vue'
import LTitle from '@/components/LTitle.vue'
import { addLiuYan } from '@/api/sgapi'
export default {
  name: 'l-fixed',
  components: {
    LPop,
    LTitle
  },
  data() {
    return {
      msgVisible: false,
      liuyanForm: {
        contactPeople: null,
        contactPhone: null,
        content: null,
      },
    }
  },
  methods: {
    onService() {
      window.open(
        'https://im1c5366d.7x24cc.com/phone_webChat.html?accountId=N000000007880&chatId=dbbd6559-7a1d-416f-9ed6-a8309e20f84e'
      )
    },
    onBackTop() {
      let timer
      timer = setInterval(() => {
        if (document.documentElement.scrollTop <= 0) {
          document.documentElement.scrollTop = 0
          clearInterval(timer)
        }
        document.documentElement.scrollTop -= 80
      }, 10)
    },
    onSubmitMsg() {
      var that = this
      addLiuYan({ ...this.liuyanForm })
        .then((res) => {
          if (res && res.code == '0') {
            that.$message.success('提交成功')
            that.liuyanForm.contactPeople = null
            that.liuyanForm.contactPhone = null
            that.liuyanForm.content = null
            that.visible = false
          } else {
            that.$message.error(res && res.message ? res.message : '提交失败')
          }
        })
        .catch((e) => {
          that.$message.error('提交失败')
        })
    },
  },
}
</script>

<style lang="less" scoped>
.fixed {
  z-index: 999;
  width: 60rem;
  height: 60rem;
  border-radius: 50%;
  box-sizing: border-box;
  border: 3rem solid #fff;
  background-color: rgba(26, 139, 249, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 228rem;
  right: 100rem;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: rgba(26, 139, 249, 1);
  }

  img {
    width: 32rem;
    height: 32rem;
  }
}

.l {
  &-fixed {
    z-index: 999;
    position: fixed;
    top: 50%;
    right: 2vw;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36rem 0;

    &__button {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 52rem;
      height: 52rem;
      border-radius: 50%;
      border: 3rem solid #fff;
      background-color: rgba(26, 139, 249, 0.4);
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        background-color: rgba(26, 139, 249, 1);
      }

      img {
        width: 32rem;
      }
    }

    &__tooltip {
      background: url('@/assets/images/tooltip.png') no-repeat left top/ 100% 100%;
      width: 150rem;
      display: flex;
      flex-direction: column;
      padding: 20rem 8rem;
      box-sizing: border-box;

      h3 {
        font-size: 18rem;
        color: #fff;
        font-weight: 600;
        text-align: center;
      }

      ul {
        margin-top: 16rem;
        display: flex;
        flex-direction: column;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(10rem);
        padding: 0 10rem;

        li {
          height: 45rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14rem;
          color: #fff;
          border-bottom: 2rem dashed #fff;
          cursor: pointer;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      button {
        margin-top: 20rem;
        outline: none;
        border: none;
        height: 48rem;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(10rem);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18rem;
        color: #fff;
        font-weight: 600;
        cursor: pointer;

        &:active {
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
