import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomePage.vue'),
    meta: {
      headHeight: '964rem',
    },
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('@/views/Project.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/cooperator',
    name: 'cooperator',
    component: () => import('@/views/Cooperator.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    children: [
      {
        path: '/',
        component: () => import('../components/login/Login.vue'),
      },

      {
        path: '/org/register',
        component: () => import('../components/login/OrgRegister.vue'),
      },
      {
        path: '/org/forget',
        component: () => import('../components/login/OrgForget.vue'),
      },
      {
        path: '/live/conference/login',
        // name:"liveConferenceLogin",
        component: () => import('../components/login/Login.vue'),
      },
      {
        path: '/live/conference/org/register',
        component: () => import('../components/login/OrgRegister.vue'),
      },
      {
        path: '/live/conference/org/forget',
        component: () => import('../components/login/OrgForget.vue'),
      },
    ],
  },
  {
    path: '/jxjy',
    name: 'jxjy',
    component: () => import('../views/Jxjy.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/excellentCourse',
    name: 'excellentCourse',
    component: () => import('../views/Kqpx.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/kqpx',
    component: () => import('../views/Kqpx.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/gzdt',
    component: () => import('../views/NewsList.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/tzgg',
    component: () => import('../views/NewsList.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/teachers',
    component: () => import('../views/Teachers.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/news/details/:id',
    name: 'newsDetails',
    component: () => import('../views/NewsDetails.vue'),
    meta: {
      headHeight: '500rem',
      headTheme: 'dark',
      index: 2,
    },
  },
  {
    path: '/PageDetails/:id',
    name: 'PageDetails',
    component: () => import('../views/PageDetails.vue'),
    meta: {
      headHeight: '500rem',
      headTheme: 'dark',
      index: 2,
    },
  },
  {
    path: '/BKDetails',
    name: 'BKDetails',
    component: () => import('../views/BKDetails.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/BookShowDetail',
    name: 'BookShowDetail',
    component: () => import('../views/BookShowDetail.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/ptjs',
    name: 'ptjs',
    component: () => import('../views/Introduction.vue'),
    meta: {
      headHeight: '964rem',
    },
  },
  {
    path: '/train',
    name: 'train',
    component: () => import('../views/Train.vue'),
    meta: {
      headHeight: '964rem',
    },
  },
  {
    path: '/kqlivelist',
    name: 'KQLiveList',
    component: () => import('../views/KQLiveList.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/projectNotice',
    name: 'ProjectNotice',
    component: () => import('../views/ProjectNotice.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/projectNoticeDetail',
    name: 'ProjectNoticeDetail',
    component: () => import('../views/ProjectNoticeDetail.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/socialWork',
    name: 'socialWork',
    component: () => import('../views/socialWork.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/socialWorkDetail',
    name: 'socialWorkDetail',
    component: () => import('../views/socialWorkDetail.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/innovate', // 治理创新
    name: 'innovate',
    component: () => import('@/views/Innovate.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/interview', // 嘉宾访谈
    name: 'interview',
    component: () => import('@/views/Interview.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/interviewDetail/:id', // 嘉宾访谈详情
    name: 'interviewDetail',
    component: () => import('@/views/InterviewDetail.vue'),
    meta: {
      headHeight: '500rem',
      headTheme: 'light',
      index: 2,
    },
  },
  {
    path: '/talentTraining', // 人才培训
    name: 'talentTraining',
    // component: () => import('@/views/TalentTraining.vue'),
    component: () => import('@/views/Rcpx.vue'),
    meta: {
      headHeight: '500rem',
    },
  },
  {
    path: '/talentTrainingDetail', // 人才培训详情
    name: 'talentTrainingDetail',
    component: () => import('@/views/TalentTrainingDetail.vue'),
    meta: {
      headHeight: '500rem',
      headTheme: 'dark',
      index: 2,
    },
  },
  {
    path: '/communityProjectManage', // 社区项目管理
    name: 'communityProjectManage',
    component: () => import('@/views/communityProjectManage.vue'),
    meta: {
      headHeight: '830rem',
      headTheme: 'light',
    },
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0
  next()
})

export default router
