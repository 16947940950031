<template>
  <div id="app">
    <l-header v-if="showHeader($route.path)" />
    <router-view />
    <l-footer />
    <l-fixed />
  </div>
</template>
<script>
import LHeader from '@/components/LHeader.vue'
import LFooter from '@/components/LFooter.vue'
import LFixed from '@/components/LFixed.vue'
export default {
  components: {
    LHeader,
    LFooter,
    LFixed,
  },
  filters: {},
  methods: {
    //是否显示头部（true显示，false不显示）
    showHeader(path) {
      if (
        path == '/login' ||
        path == '/org/register' ||
        path == '/org/forget' ||
        path == '/live/conference/login' ||
        path == '/live/conference/org/register' ||
        path == '/live/conference/org/forget' 
      ) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>

<style lang="less"></style>
