import axios from 'axios';
import { encrypt, decrypt } from "./aes";

// 创建axios
const service = axios.create({
    baseURL: '/'
});
const aesKey = 'tF5dew2u3ng4n28xj2nc0zHm';

// 添加请求拦截器
service.interceptors.request.use(function (config) {
    //获取token
    let token = localStorage.getItem("GW-SGJXJY-TOKEN")
    if (!token) {
        token = sessionStorage.getItem("GW-SGJXJY-TOKEN")
    }
    if (token) {
        config.headers['Authorization'] = token;
    }
    // 在发送请求之前做些什么
    if (config && config.encrypt) {
        //接口需要加密
        let str = '';
        if (config.data) {
            //console.log(JSON.stringify(config.data));
            str = encrypt(JSON.stringify(config.data), aesKey);
        }
        config.data = str;
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    if (response.data) {
        try {
            const result = decrypt(response.data, aesKey);
            if (result) {
                if (typeof (result) == 'string') {
                    const obj = JSON.parse(result);
                    if (obj) {
                        response.data = obj;
                    }
                }
            }
        } catch (e) { console.warn(e) }
    }
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default service;