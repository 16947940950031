<template>
  <div class="l-title">
    <div class="l-title__main" :class="themeColor">
      <div class="l-title__tabs" v-if="tabs.length">
        <div class="l-title__label" :class="{ active: activeIndex == index }" @click="onChange(index)"
          v-for="(item, index) in tabs" :key="index">
          {{ item }}
        </div>
      </div>
      <div class="l-title__label active" v-else>
        {{ title }}
      </div>
    </div>
    <div class="l-title__suffix">
      <slot name="suffix">
        <router-link :to="path" v-if="path">
          更多
          <img src="@/assets/images/arrow_r_2.png" alt="" />
        </router-link>
        <a href="javascript:;" v-else-if="showMore" @click="showMoreClick">{{showMoreText}}</a>

      </slot>

    </div>
  </div>
</template>

<script>
export default {
  name: "l-title",
  props: {
    title: String,
    subtitle: String,
    path: {
      type: String,
      default: "",
    },
    tabs: {
      type: Array,
      default: () => {
        return []
      },
    },
    themeColor: {
      type: String,
      default: "black",
    },
    showMoreText:{
      type: String,
      default: "更多",
    },
    showMore:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      activeIndex: 0,
    }
  },
  methods: {
    onChange(index) {
      this.activeIndex = index
      this.$emit("change", index)
    },
    showMoreClick(){
      this.$emit("showMoreClick")
    }
  },
}
</script>

<style lang="less" scoped>
.l {
  &-title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    &__main {
      position: relative;

      &::before {
        display: inline-flex;
        content: "";
        width: 100%;
        min-width: 312rem;
        height: 2rem;
        background: #C2D1E0;
        position: absolute;
        left: 0;
        top: 1rem;
      }
    }

    &__suffix {
      display: inline-flex;

      a {
        width: 82rem;
        height: 34rem;
        border-radius: 17rem;
        border: 1rem solid #1a8bf9;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 14rem;
        color: #1a8bf9;
        line-height: 1;

        img {
          width: 20rem;
        }
      }
    }

    &__label {
      font-size: 18rem;
      color: #1a1c1f;
      font-weight: 500;
      padding-top: 32rem;
      position: relative;
      z-index: 11;
      cursor: pointer;

     

      &::after {
        display: inline-flex;
        content: "/";
        font-size: 18rem;
        color: #c2d1e0;
        position: absolute;
        right: -58rem;
        bottom: 0;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      &.active {
        font-size: 32rem;
        // border-top: 4rem solid #1a1c1f;
        &::before{
          display: block;
          content: "";
          width: 100%;
          max-width: 160rem;
          height: 4rem;
          background: #1a1c1f;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    &__tabs {
      display: flex;
      align-items: flex-end;
      gap: 0 112rem;

      .l-title__label {
        padding-top: 0;

        &.active {
          padding-top: 32rem;
        }
      }
    }
  }
}

.l-title__main.white {
  &::before {
    background: rgba(255, 255, 255, 0.5);
  }

  .l-title__label {
    color: #fff;

    &.active {
      border-color: #fff;
    }
  }
}</style>
