import CryptoJS from 'crypto-js';

export function decrypt(cipher, k) {//解密
    var key = CryptoJS.enc.Utf8.parse(k);
    var iv = CryptoJS.enc.Utf8.parse(k);
    var decrypted = CryptoJS.AES.decrypt(cipher, key, {
        iv: iv, mode: CryptoJS.mode.CBC
    });
    var decryedata = CryptoJS.enc.Utf8.stringify(decrypted);
    return decryedata;
}

export function encrypt(data, k) {//加密方法
    var key = CryptoJS.enc.Utf8.parse(k);
    var iv = CryptoJS.enc.Utf8.parse(k);
    return CryptoJS.AES.encrypt(data, key, {iv: iv, mode: CryptoJS.mode.CBC}).toString();
}
